import { isMobile } from 'react-device-detect'

import MCKUP from "./assets/mckup_dropora.png"
import LOGO from "./assets/logo.png"

import { useTranslation } from 'react-i18next'

function App() {
  const { t, i18n } = useTranslation()

  const handleLangChange = lang => {
    i18n.changeLanguage(lang);
  }

  return (
    <>
      {i18n.language === "en" || i18n.language === "en-US" ?
        <div className="lg" onClick={() => handleLangChange('fr')}>
          FR
        </div>
        :
        <div className="lg" onClick={() => handleLangChange('en')}>
          EN
        </div>
      }
      <div className="app">
        {!isMobile && <div className="part_one">
          <img src={MCKUP} alt="Dropora mobile app" className="mckup" />
        </div>}
        <div className="part_two">
          <div className="coming">
            {t('coming')}
          </div>
          <img src={LOGO} alt="Dropora logo" className="logo" />
          <div className="text">
            {t('collectibles')}
          </div>
          <div className="tags">
            {t('collect')}
            <br />
            {t('share')}
            <br />
            {t('drop')}
          </div>
          {isMobile && <img src={MCKUP} alt="Dropora mobile app" className="mckup" />}
          <button onClick={() => window.open("mailTo:contact@dropora.com")}>
            {t('get_in_touch')}
          </button>
          <div className="text">
            {t('learn_more')}
          </div>
        </div>
      </div>
      <div className="legal">
        {t('legal')}
        <br />
        Copyright @Dropora 2024
      </div>
    </>
  );
}

export default App;
